import React from "react";
import { FaPhone } from "react-icons/fa";
import "./css/GetQuote.css";

const GetQuote = () => {
  const handleQuoteClick = () => {
    window.location.href =
      "mailto:info@247workforceinc.net?subject=Quote Request";
  };

  return (
    <div className="get-quote-container mt-5">
      <div className="left-section-quote">
        <h2>
          EFFICIENT, SAFE, RELIABLE <br />
          DEMOLITION YOU CAN GET.
        </h2>
      </div>
      <div className="right-section-quote">
        <div className="button-container">
          <button className="quote-button" onClick={handleQuoteClick}>
            Get a Quote
          </button>
          <a href="tel:780-884-2997" className="phone-button">
            <FaPhone className="phone-icon" />
            780-884-2997
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetQuote;
