import React, { Suspense, lazy } from "react";
import { Element, scroller } from "react-scroll";
import "./css/Home.css"; // Assuming you have a Home.css for styles

const ServicesHome = lazy(() => import("./ServicesHome"));
const Projects = lazy(() => import("./Projects"));
const GetQuote = lazy(() => import("./GetQuote"));
const WhyChoose = lazy(() => import("./WhyChoose"));
const Footer = lazy(() => import("./Footer"));

function Home() {
  const scrollToProjects = () => {
    scroller.scrollTo("projects", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div>
        <Element name="home">
          <div className="home-card-container">
            <img
              src="/images/crane_grab_scrap.jpg"
              alt="Crane grabbing scrap"
              className="crane-grab-scrap"
            />
            <div className="home-overlay-content">
              <div className="home-text-container">
                <h1>
                  "Breaking Barriers, <br />
                  Building Futures"
                </h1>
                <p>
                  Our tagline encapsulates the idea of overcoming obstacles and
                  creating opportunities for a brighter future. It signifies the
                  process of dismantling limitations or challenges to pave the
                  way for progress and growth, ultimately leading to the
                  establishment of a better tomorrow.
                </p>
                <button onClick={scrollToProjects} className="home-button">
                  OUR PROJECTS
                </button>
              </div>
            </div>
          </div>
        </Element>

        <div className="home-services-section">
          <ServicesHome />
        </div>

        <div class="home-whychoose mt-5">
          <WhyChoose />
        </div>

        <GetQuote />

        <Element name="projects">
          <Projects />
        </Element>

        <Footer />
      </div>
    </Suspense>
  );
}

export default Home;
