import React from "react";
import "./css/Projects.css"; // Import the new CSS file

function Projects(props) {
  return (
    <div className="projects-container mt-5">
      <div className="row">
        <div className="col-12">
          <h1 className="projects-text-center">OUR PROJECTS</h1>
          <p className="text-center"></p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/001.jpg"
                alt="Mold Remediation"
                className="projects-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/002.jpg"
                alt="Demolition"
                className="projects-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/003.jpg"
                alt="Concrete Removal and Recycling"
                className="projects-img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/004.jpg"
                alt="Mold Remediation"
                className="projects-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/005.jpg"
                alt="Demolition"
                className="projects-img"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <div className="projects-card">
            <div className="projects-card-body text-center">
              <img
                src="/images/our_projects/006.jpg"
                alt="Concrete Removal and Recycling"
                className="projects-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
