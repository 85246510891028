import React from "react";
import "./css/WhyChoose.css"; // Import the CSS file

function WhyChoose() {
  return (
    <div className="whychoose-container mt-0">
      <div className="whychoose-card-container">
        <div className="whychoose-text-container">
          <h1>WHY CHOOSE 24/7 WORKFORCE</h1>
          <ul className="whychoose-list mt-4">
            <li>
              <h3>ON SCHEDULE</h3>
              <p>
                Able to meet and work with your schedule without compromising
                quality of work. We can work with our client’s schedule –
                whether it’s fast paced, or in multiple phasing
              </p>
            </li>
            <li>
              <h3 className="mt-4">Expert Demolition Services</h3>
              <p>
                Skilled professionals with extensive experience in safe and
                efficient demolition, ensuring your project is handled with
                precision.
              </p>
            </li>
            <li>
              <h3 className="mt-4">Safety First Approach</h3>
              <p>
                Strict adherence to safety standards to ensure a secure work
                environment for our team and your site.
              </p>
            </li>
            <li>
              <h3 className="mt-4">Customer-Centric Approach</h3>
              <p>
                Dedicated to excellent customer service, ensuring clear
                communication and satisfaction throughout the project.
              </p>
            </li>
          </ul>
        </div>
        <div className="whychoose-image-container">
          <img
            src="/images/crane_grab_scrap.jpg"
            alt="Crane grabbing scrap"
            className="whychoose-image"
          />
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
