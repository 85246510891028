import React from "react";
import Projects from "./Projects";
import GetQuote from "./GetQuote";
import WhyChoose from "./WhyChoose";
import Footer from "./Footer";
import "./css/Services.css";

function Services() {
  return (
    <div>
      <div className="card-container-overlay">
        <img
          src="/images/workers.png"
          alt="Workers"
          className="workers-image"
        />
        <div className="overlay-content">
          <div className="text-container-overlay">
            <h1>OUR SERVICES</h1>
            <p>Let's Create Awesome Projects Together</p>
          </div>
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/environmental_engineer.jpg"
            alt="Water and Flood Damage Restoration"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">water & flood Damage cleaning</h1>
          <p>
            Water and flood damage cleaning refers to the comprehensive process
            of cleaning properties affected by water incidents, whether from
            flooding, leaks, or other sources. This involves several critical
            steps, beginning with a thorough assessment and inspection to
            determine the extent of the damage. Next, water extraction is
            performed using pumps and vacuums, followed by drying and
            dehumidification with specialized equipment to ensure all moisture
            is eliminated. Cleaning and sanitizing the affected areas is
            crucial, involving deep cleaning and disinfection to prevent mold
            and bacteria growth.
          </p>
        </div>
      </div>

      <div className="card-container" style={{ backgroundColor: "orange" }}>
        <div className="services-text-container-one">
          <h1 className="mt-4">fire & smoke Damage cleaning</h1>
          <p>
            Fire and smoke damage cleaning is a comprehensive process aimed at
            cleaning properties affected by fire and smoke. This process begins
            with an initial assessment and inspection to evaluate the extent of
            the damage. Water extraction and drying may be necessary if
            firefighting efforts involved water. The cleaning process involves
            removing soot and smoke residues from surfaces, deodorizing to
            eliminate smoke odors, and addressing any water damage that may have
            occurred.
          </p>
        </div>
        <div className="image-container">
          <img
            src="/images/demolished_building.jpg"
            alt="Fire and Smoke Damage Restoration"
            className="services-image"
          />
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/goggles_mask.jpg"
            alt="wind & hail Damage restoration"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">Lead Removal</h1>
          <p>
            Lead removal involves eliminating lead-based materials from
            buildings and environments to reduce health risks. Found in older
            paints, pipes, and construction materials, lead poses significant
            health hazards, particularly to children and pregnant women, as it
            can cause serious neurological and developmental issues. The removal
            process includes assessment to identify the extent of contamination,
            planning to develop a safe removal strategy, containment to prevent
            the spread of lead particles, and the actual removal using
            specialized techniques and equipment. Proper disposal and cleanup
            follow to ensure the area is safe and lead-free.
          </p>
        </div>
      </div>

      <div className="card-container" style={{ backgroundColor: "orange" }}>
        <div className="services-text-container-one">
          <h1 className="mt-4">mold remediation</h1>
          <p>
            Mold remediation is the process of identifying, removing, and
            preventing mold growth in properties to ensure a safe and healthy
            living environment. This begins with a thorough inspection to locate
            all areas affected by mold, often using specialized equipment to
            detect hidden moisture. Containment measures are implemented to
            prevent the spread of mold spores to unaffected areas during the
            cleanup process. The remediation involves removing contaminated
            materials, such as drywall and carpeting, that cannot be salvaged.
            Surfaces are then cleaned and treated with antimicrobial solutions
            to kill mold spores and inhibit future growth.
          </p>
        </div>
        <div className="image-container">
          <img
            src="/images/thermal_fog.jpg"
            alt="mold remediation"
            className="services-image"
          />
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/hazmat_paint.jpg"
            alt="asbestos abatement"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">asbestos abatement</h1>
          <p>
            Asbestos abatement is the careful process of identifying, removing,
            and disposing of asbestos-containing materials to eliminate health
            risks associated with asbestos exposure. The process begins with a
            detailed inspection and assessment to locate all asbestos materials
            in the property. Specialized containment measures are put in place
            to prevent the release of asbestos fibers during removal, including
            sealing off the affected area and using negative air pressure
            systems. Trained professionals then carefully remove or encapsulate
            the asbestos materials, following strict safety protocols to
            minimize exposure.
          </p>
        </div>
      </div>

      <div className="card-container" style={{ backgroundColor: "orange" }}>
        <div className="services-text-container-one">
          <h1 className="mt-4">post-construction cleanup</h1>
          <p>
            Post-construction cleanup is the thorough process of cleaning and
            preparing a newly built or renovated property for occupancy. This
            process begins once construction work is completed, addressing the
            extensive dust, debris, and leftover materials that are typically
            present. The cleanup includes removing construction debris, such as
            wood, drywall scraps, and packaging materials, followed by a
            detailed cleaning of all surfaces. This involves vacuuming and
            dusting walls, ceilings, and floors, as well as cleaning windows,
            fixtures, and appliances. Special attention is given to removing any
            paint splatters, adhesive residues, and other construction-related
            stains.
          </p>
        </div>
        <div className="image-container">
          <img
            src="/images/man_with_equipment.jpg"
            alt="post-construction cleanup"
            className="services-image"
          />
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/holding_cleaning-bucket.jpg"
            alt="asbestos abatement"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">Commercial building cleaning</h1>
          <p>
            Commercial building cleaning encompass a comprehensive range of
            services aimed at maintaining the cleanliness of commercial
            properties. This includes regular cleaning tasks such as vacuuming,
            dusting, mopping, and sanitizing common areas, restrooms, offices,
            and other spaces within the building. Beyond routine cleaning, it
            involves managing waste disposal, window cleaning, carpet cleaning,
            and maintaining high-traffic areas.
          </p>
        </div>
      </div>

      <div className="card-container" style={{ backgroundColor: "orange" }}>
        <div className="services-text-container-one">
          <h1 className="mt-4">infection control services</h1>
          <p>
            Infection control services encompass a range of activities and
            protocols aimed at preventing and managing the spread of infections,
            particularly within healthcare settings such as hospitals, clinics,
            and nursing homes. These services typically involve surveillance and
            monitoring to track infection rates and identify outbreaks early,
            allowing for the implementation of control measures. Policies and
            procedures are developed and enforced to guide infection prevention
            practices, including hand hygiene, the use of personal protective
            equipment (PPE), and sterilization techniques.
          </p>
        </div>
        <div className="image-container">
          <img
            src="/images/hazmat_cross.jpg"
            alt="infection-control-services"
            className="services-image"
          />
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/vacuum_indoors.jpg"
            alt="carpet cleaning"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">carpet cleaning</h1>
          <p>
            Carpet cleaning involves the process of removing dirt, stains, and
            allergens from carpets to improve their appearance and extend their
            lifespan. Professional carpet cleaning services typically use
            various methods, including hot water extraction (steam cleaning),
            which injects hot water and a cleaning solution into the carpet
            fibers before extracting the mixture with a powerful vacuum.
          </p>
        </div>
      </div>

      <div className="card-container" style={{ backgroundColor: "orange" }}>
        <div className="services-text-container-one">
          <h1 className="mt-4">Demolition Works</h1>
          <p>
            Demolition works refer to the process of dismantling, tearing down,
            or destroying buildings and structures, often using various
            techniques based on the project's scale and nature. These works
            serve multiple purposes, including site clearance for new
            construction, removing unsafe structures, urban renewal, and
            environmental remediation. The goal is to effectively and safely
            prepare the site for future use or to address safety and
            environmental concerns.
          </p>
        </div>
        <div className="image-container">
          <img
            src="/images/person_construction.jpg"
            alt="infection-control-services"
            className="services-image"
          />
        </div>
      </div>

      <div className="card-container">
        <div className="image-container">
          <img
            src="/images/workers_overalls.jpg"
            alt="Staffing Services"
            className="services-image"
          />
        </div>
        <div className="services-text-container">
          <h1 className="mt-4">Staffing Services</h1>
          <p>
            24/7 Workforce Inc. provide businesses with the workforce they need
            by recruiting, hiring, and placing employees in various roles. These
            services can be temporary, permanent, or contract-based, depending
            on the client's requirements. Staffing agencies handle the entire
            hiring process, including sourcing candidates, conducting
            interviews, and managing payroll and benefits. This allows companies
            to focus on their core operations while ensuring they have access to
            qualified personnel.
          </p>
        </div>
      </div>

      <div className="mt-5">
        <WhyChoose />
      </div>

      <GetQuote />

      <Projects />

      <Footer />
    </div>
  );
}

export default Services;
