import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"; // Importing the icons
import "./css/Navbar.css"; // Assuming you have a Navbar.css for styles

function Navbar() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <header>
      <div className="top-bar">
        <NavLink to="/" className="navbar-brand">
          <img
            src="/images/logo_247workforce.JPG"
            alt="Company Logo"
            className="logo"
          />
        </NavLink>
        <div className="contact-info">
          <div className="contact-info-number">
            <span>
              <a href="tel:780-884-2997" className="icon">
                <FaPhoneAlt />
                <span className="contact-text"> 780-884-2997</span>
              </a>
            </span>
            <span>
              <a href="tel:604-505-6688" className="icon">
                <FaPhoneAlt />
                <span className="contact-text">BC: 604-505-6688</span>
              </a>
            </span>
          </div>
          <div className="contact-info-email">
            <span>
              <a
                href="mailto:info@247workforceinc.net?subject=Quote Request"
                className="icon"
              >
                <FaEnvelope />
                <span className="contact-text"> info@247workforceinc.net</span>
              </a>
            </span>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-navbar">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to="/"
                  exact="true"
                  className="nav-link"
                  activeclassname="active"
                >
                  HOME
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/services"
                  className="nav-link"
                  activeclassname="active"
                >
                  SERVICES
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link"
                  activeclassname="active"
                >
                  ABOUT US
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contact"
                  className="nav-link"
                  activeclassname="active"
                >
                  CONTACT US
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
