import React from "react";
import { Link } from "react-router-dom";
import "./css/Footer.css";

const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="main-footer">
      <div className="above-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 pl-lg-0">
              <div className="logo-column">
                <img
                  src="/images/logo_247workforce.JPG"
                  alt="Logo"
                  className="logo"
                />
                <div className="orange-bar mt-4"></div>
                <p className="working-hours">
                  <i className="fas fa-clock"></i> Open 24 hours
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="menu-column">
                <h2>MENU</h2>
                <ul>
                  <li>
                    <Link
                      to="/"
                      onClick={handleLinkClick}
                      style={{ color: "inherit" }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services"
                      onClick={handleLinkClick}
                      style={{ color: "inherit" }}
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      onClick={handleLinkClick}
                      style={{ color: "inherit" }}
                    >
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="find-us-column">
                <h2>FIND US</h2>
                <ul>
                  <li>
                    <i className="fas fa-home" style={{ color: "orange" }}></i>{" "}
                    <div className="address">
                      8316 156 St Edmonton Alberta T5R 1Y4
                    </div>
                  </li>

                  <li>
                    <i className="fas fa-home" style={{ color: "orange" }}></i>{" "}
                    <div className="address">6138 128 St Surrey BC V3X 0K9</div>
                  </li>

                  <li>
                    <i className="fas fa-phone" style={{ color: "orange" }}></i>{" "}
                    780-884-2997
                  </li>

                  <li>
                    <i className="fas fa-phone" style={{ color: "orange" }}></i>{" "}
                    BC: 604-505-6688
                  </li>

                  <li>
                    <i
                      className="fas fa-envelope"
                      style={{ color: "orange" }}
                    ></i>{" "}
                    info@247workforceinc.net
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 mb-4">
              <div className="share-column">
                <h2>SHARE WITH US</h2>
                <div className="social-icons">
                  <a href="https://www.facebook.com/">
                    <i
                      className="fab fa-facebook"
                      style={{ color: "orange" }}
                    ></i>
                  </a>
                  <a href="https://www.instagram.com/">
                    <i
                      className="fab fa-instagram"
                      style={{ color: "orange" }}
                    ></i>
                  </a>
                  <a href="https://www.youtube.com/">
                    <i
                      className="fab fa-youtube"
                      style={{ color: "orange" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="below-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright">
                <i className="fas fa-copyright"></i> 2024 All Rights Reserved |
                24/7 - Workforce
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
