import React from "react";
import GetQuote from "./GetQuote";
import Projects from "./Projects";
import Footer from "./Footer";
import "./css/About.css"; // Import the new CSS file

const About = () => {
  return (
    <div>
      <div className="about-card-container-overlay">
        <img
          src="/images/workers.png"
          alt="Workers"
          className="about-workers-image"
        />
        <div className="about-overlay-content">
          <div className="about-text-container-overlay">
            <h1>ABOUT 24/7 WORKFORCE</h1>
            <p>Let's Create Awesome Projects Together</p>
          </div>
        </div>
      </div>

      {/* <div className="about-full-width-container about-orange-background">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 about-image-container">
            <img
              src="/images/goggles_mask.jpg"
              alt="Hazmat Paint"
              className="about-services-image img-fluid rounded-circle"
            />
          </div>
          
          <div className="col-lg-6 col-md-12 about-text-container">
            <h4 className="mt-4">
              A COMPANY BUILT ON A POSITIVE ATTITUDE
              <span className="horizontal-bar"></span>
            </h4>
            <h1 className="mt-4">OUR STORY</h1>
            <p>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
              aliquet aliquam quam, sagittis luctus risus pretium et. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos. Curabitur fringilla ligula eget lacus finibus
              auctor. Suspendisse commodo eros in quam bibendum, vestibulum
              elementum risus porttitor. Sed ut tempus."
            </p>
          </div>
        </div>
      </div> */}

      <div className="commitment-container">
        <h2 className="commitment-text-center mt-4">OUR COMMITMENT</h2>
        <p>
          "At 24/7 Workforce, our commitment is to deliver safe, efficient, and
          environmentally responsible demolition services, ensuring the highest
          standards of quality and customer satisfaction in every project we
          undertake. We prioritize the well-being of our team and clients
          through strict safety protocols and continuous training. Our
          dedication to innovation and sustainability drives us to adopt
          cutting-edge techniques and eco-friendly practices, minimizing our
          environmental impact. We strive to exceed expectations by providing
          customized solutions tailored to the unique needs of each project,
          fostering long-lasting relationships built on trust and reliability."
        </p>
      </div>

      {/* <div className="meet-the-team-container">
        <h2 className="text-center">MEET THE TEAM</h2>
        <div className="team-cards-container">
          <div className="team-card">
            <img
              src="/images/goggles_mask.jpg"
              alt="Team Member 1"
              className="team-member-image"
            />
            <h3>Team Member 1</h3>
            <p></p>
          </div>
          <div className="team-card">
            <img
              src="/images/goggles_mask.jpg"
              alt="Team Member 2"
              className="team-member-image"
            />
            <h3>Team Member 2</h3>
            <p></p>
          </div>
        </div>
      </div> */}

      <GetQuote />
      <Projects />
      <Footer />
    </div>
  );
};

export default About;
