import React, { useState } from "react";
import Footer from "./Footer";
import "./css/Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:admin@247workforceinc.net?subject=${encodeURIComponent(
      // const mailtoLink = `mailto:testmarco36@gmail.com?subject=${encodeURIComponent(
      formData.subject
    )}&body=${encodeURIComponent(
      `Name: ${formData.name}\nPhone: ${formData.phone}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`
    )}`;
    window.location.href = mailtoLink;
    setAlert("Email client opened. Please check and send the email.");
  };

  const handleQuoteClick = () => {
    window.location.href =
      "mailto:admin@247workforceinc.net?subject=Quote Request";
  };

  return (
    <div>
      <div className="contact-card-container-overlay">
        <img
          src="/images/workers.png"
          alt="Workers"
          className="contact-workers-image"
        />
        <div className="contact-overlay-content">
          <div className="contact-text-container-overlay">
            <h1>CONTACT US</h1>
            <p>Let's Create Awesome Projects Together</p>
          </div>
        </div>
      </div>

      <div className="contact-sections-container">
        <div className="contact-left-section">
          <h2>REQUEST A QUOTE</h2>
          <p>
            Looking for a project quote? Fill out a Quote Request, and we'll
            follow up within one business day.
          </p>
          <div className="spacer-3"></div>
          <button className="quote-button" onClick={handleQuoteClick}>
            Get a Quote
          </button>
          <div className="spacer-2 mt-5"></div>
          <hr />
          <div className="spacer-3 mt-5"></div>
          <p>780-884-2997</p>
          <div className="spacer-2 mt-5"></div>
          <p>admin@247workforceinc.net</p>
          <div className="spacer-1 mt-5"></div>
          <p>
            8316 156 St Edmonton Alberta T5R 1Y4
            <br />
            <br />
            6138 128 St Surrey BC V3X 0K9
          </p>
        </div>
        <div className="contact-right-section">
          <h2>GENERAL INQUIRIES</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="phone" className="required">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <label htmlFor="email" className="required">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="subject" className="required">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <label htmlFor="message" className="required">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="send-button">
              SEND
            </button>
          </form>
          {alert && <div className="alert">{alert}</div>}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
